.projects-add-new-drop-down .ant-dropdown-menu-item-group-title {
  color: #000;
  font-size: 16px;
}

.projects-add-new-drop-down .ant-dropdown-menu-item:hover,
.projects-add-new-drop-down .ant-dropdown-menu-submenu-title:hover,
.projects-add-new-drop-down .ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.projects-add-new-drop-down .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active,
.projects-add-new-drop-down .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active,
.projects-add-new-drop-down .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
  background: rgba(255, 114, 38, 0.1);
}

.projects-add-new-drop-down .ant-dropdown-menu-item:hover .text-style,
.projects-add-new-drop-down .ant-dropdown-menu-item:hover .change-icon-color {
  color: #ff7226;
}

.projects-add-new-drop-down .text-style {
  padding-left: 8px;
}

.projects-add-new-drop-down .ant-dropdown-menu-item {
  padding: 8px 16px;
}

.projects-add-new-drop-down .ant-dropdown-menu-item-group-list {
  margin: 0px;
}

.assetDetails .item-valuation-dcf {
  width: 19px;
  height: 19px;
}

.assetDetails .item-rent-role {
  width: 22px;
  height: 22px;
}

.assetDetails .item-valuation-setting {
  width: 17px;
  height: 17px;
}

.assetDetails .item-cash-flows {
  width: 17px;
  height: 17px;
}

.assetDetails.site-layout-background {
  padding: 0px !important;
}

.assetDetails .ant-menu-horizontal {
  display: none;
}

.assetDetails .ant-tabs-tab .ant-tabs-tab-btn p,
.assetDetails .ant-tabs-tab .ant-tabs-tab-btn {
  color: rgba(111, 113, 115, 1);
}

.assetDetails .ant-tabs-tab .ant-tabs-tab-btn:hover p,
.assetDetails .ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #00215b;
}

.assetDetails .ant-tabs-tab .ant-tabs-tab-btn:hover img {
  filter: invert(75%) sepia(85%) saturate(1605%) hue-rotate(199deg) brightness(50%) contrast(118%) !important;
}

.assetDetails .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn p,
.assetDetails .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00215b;
}

.assetDetails .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn img {
  filter: invert(75%) sepia(85%) saturate(1605%) hue-rotate(199deg) brightness(50%) contrast(118%) !important;
}

.assetDetails .ant-tabs-tab-btn:focus {
  color: #00215b;
}

/* .assetDetails .ant-tabs  .ant-tabs-nav {
  background-color: transparent !important;
} */

.asset-details-calculation .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.assetDetails .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
  /* background-color: #ffffff; */
  background-color: #f5f5f5;
  justify-content: center;
  border-radius: 5px;
  display: flex;

}

.assetDetails .statisticsArea {
  background-color: #ffffff;
  border-radius: 5px;
  margin-bottom: 27px;
}

.assetDetails .stickyTabs .statisticsArea {
  border-color: #00215a;
}

.ant-tabs-nav-operations-hidden {
  height: 0;
}

.assetDetails .ant-statistic {
  text-align: center;
}

.asset-details-calculation .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: flex-start;
  border: 1.5px solid #B0BACD;
  padding: 9px 15px;
}

.ant-tabs-content-holder .ant-tabs-nav-wrap {
  background-color: transparent !important;
}

.asset-details-calculation .ant-tabs-card .ant-tabs-tab-active {
  background: #00215b !important;
}

.asset-details-calculation .ant-tabs-card .ant-tabs-tab:first-child {
  border-radius: 5px 0 0 5px !important;
}

.asset-details-calculation .ant-tabs-card .ant-tabs-tab:nth-child(2) {
  border-radius: 0 5px 5px 0 !important;
}

.asset-details-calculation .ant-tabs-card .ant-tabs-tab {
  background: #fff;
  color: #00215b;
  border: 2px solid #00215b;
  place-content: center;
  margin-right: 0;
}

.ant-tabs-ink-bar-animated {
  position: absolute;
  bottom: 6px;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 0px;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  border-radius: 5px !important;
  border: 2px solid #00215b;
  color: #00215b;
  width: 160px;
  place-content: center;
}

.assetdetails-tabs-pane {
  color: #6f7173;
  font-size: 20px;
  background-color: red;
}

.assetDetails .ant-tabs-tab:hover {
  color: #00215b;
}

.incomeAndCost .ant-card-body {
  padding: 0px 10px 10px !important;
}

.incomeAndCost .add-row-btn {
  border: 0px;
  border-radius: 0px;
  background-color: transparent;
}

.incomeAndCost .delete-row-btn {
  color: #ff3636 !important;
  border: 0px;
  border-radius: 0px;
  border-right: 1px solid #9e9e9e;
  padding-right: 10px;
  background-color: transparent;
}

/* .incomeAndCost .delete-row-btn:hover {
  color: #ff3636 !important;
  border: 0px !important;
  border-radius: 0px;
  border-right: 1px solid #9e9e9e !important;
  background-color: transparent;
} */

/* .incomeAndCost .delete-row-btn:focus {
  color: #ff3636 !important;
  border: 0px !important;
  border-radius: 0px;
  border-right: 1px solid #9e9e9e !important;
  background-color: transparent;
} */

.incomeAndCost .ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-text):not(.ant-btn-link):focus,
.ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-text):not(.ant-btn-link):hover {
  color: #00215b;
}

.reset-dropdown-btn {
  width: 200px;
  color: #ffffff;
  border-radius: 5px;
  background-color: #00215b;
}

.reset-dropdown-btn:hover {
  color: #ffffff !important;
  background-color: #00215b !important;
}

.calculationComponent .ant-table-thead>tr>th {
  padding: 11px;
}

/* .cashflows-first-btns {
  width: 500px;
} */

.cashflows-monthly-btn {
  color: #00215b;
  background: #ffffff;
  padding: 10px 45px;
  border-radius: 4px;
  border: #00215b 1px solid;
}

.ant-switch-checked {
  background-color: #00215b;
}

.cashflows-monthly-btn.active,
.cashFlowsComponent .cashflows-monthly-btn:hover {
  color: white !important;
  background: #00215b !important;
}

/* .cashflows-monthly-btn:focus {
  color: white;
  background: #00215b;
} */

/* .cashFlowsComponent .cashflows-monthly-btn:hover {
  background-color: #00215b !important;
  border: 1px solid #00215b !important;
  color: #ffffff !important;
} */

.cashflows-yearly-btn {
  color: #00215b;
  background: #ffffff;
  padding: 10px 45px;
  border: 1px solid #00215b;
  border-radius: 4px;
}

.cashFlowsComponent .ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-text):not(.ant-btn-link):focus,
.cashFlowsComponent .ant-btn:not(.ant-btn-primary):not(.ant-btn-dangerous):not(.ant-btn-text):not(.ant-btn-link):hover {
  background-color: #ffffff;
  border: 1px solid #00215b;
  color: #00215b;
}

.cashFlowsComponent .show-tenants {
  color: #6f7173;
  font-size: 15px;
}

.cashflows-export-btn,
.cashflows-export-btn>h5 {
  background: #ffffff;
  border-radius: 4px;
}

.cashflows-export-btn>h5 {
  color: #6f7173;
  font-size: 15px;
}

.cashflows-filter-icon {
  color: #6f7173;
}


/* .areaAndIncome .ant-slider-handle:focus {
  border: 1px solid #eeeeee;
  box-shadow: none;
}

.areaAndIncome .ant-slider-handle {
  width: 13px;
  height: 13px;
  border: 1px solid #b2b5b8;
  margin-top: -3px;
  background: white;
  box-shadow: none;
  transition: none;
}

.areaAndIncome .ant-slider-handle:hover {
  border: 1px solid #b2b5b8;
  box-shadow: none;
}

.areaAndIncome .ant-slider-rail {
  height: 8px;
  background: #eeeeee !important;
}

.areaAndIncome .ant-slider-rail:hover {
  background: #eeeeee;
} */

.areaAndIncome .empty-input {
  width: 70px;
  border: 1px solid #b7b7b7;
}

.areaAndIncome .current-rent-indexation-cascader {
  width: 230px;
}

.sqm-text-color {
  color: #545454;
}

.bp-input {
  width: 50px;
  border: 1px solid #b7b7b7;
  padding: 12px;
}

.sync-text-color {
  color: #6f7173;
}

.financingComponent .ant-table-tbody>tr>td {
  padding: 5px !important;
}

.first-calculation-table .ant-table-thead>tr>th {
  font-size: 13px;
}

.calculation-table .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
  border: 0px;
}

.calculation-table .ant-table table {
  border-spacing: 0px 8px;
  color: #000000;
}

.text-black {
  color: #000;
}

.firstCalculationTable .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table {
  border: 0px;
}

#rc-tabs-1-panel-2>div>div:nth-child(5)>div:nth-child(1)>div.holdingPeriod>div>div>div.ant-row>div:nth-child(1)>div {
  margin: 0px;
}

.office-retail-building {
  display: inline-flex;
  font-size: 16px;
  color: #969696;
}

.user-modal .ant-col-24.ant-form-item-label>label {
  color: #000000;
  font-weight: 500;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  /* padding: 5px 12px; */
  cursor: pointer;
}

/* .editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
} */

.rentRoll .ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
}

/* ant-tabs-nav */
.rentRoll .ant-tabs-nav {
  margin-bottom: 0px;
  background-color: transparent !important;
}

.rentRoll .ant-tabs-nav-list {
  background: transparent;
}

.rentRoll .ant-tabs-tab {
  background-color: #f5f5f5;
  color: #00215b !important;
  height: 45px;
  border: 1px solid #00215b;
  padding: 16px;
  /* border-radius: 5px; */
  /* margin-top: 20px; */
  margin-right: 0px;
}

.rentRoll .ant-tabs-nav-operations {
  padding: 0 !important;
}

.rentRoll .ant-tabs-tab .ant-tabs-tab-btn {
  color: #00215b !important;
}

.rentRoll .ant-tabs-tab:first-child {
  border-radius: 4px 0 0 4px;
}

.rentRoll .ant-tabs-tab:nth-child(4) {
  border-radius: 0 4px 4px 0;
}

.rentRoll .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #ffffff !important;
}

.rentRoll .ant-tabs-tab:hover {
  color: #ffffff;
  background-color: #00215b;
}

.rentRoll .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}

.rentRoll .ant-tabs-tab-active {
  color: #ffffff !important;
  background-color: #00215b !important;
}

.rentRoll .ant-tabs-tab-btn:focus {
  color: #ffffff;
}

.rentRoll .ant-tabs-ink-bar {
  display: none;
}

/* .rentRoll .ant-tabs-tab {
  padding-top: 9px;
  padding-bottom: 9px;
} */

/* .rentRoll .ant-tabs-tab {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
} */

.rentRoll .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.rentRoll .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:active {
  color: #fff !important;
}

.ant-table-row-light {
  background-color: #f2f4f7;
}

.ant-table-row-level {
  background-color: #ffffff;
}

.calculation-table .ant-table-thead>tr>th {
  padding: 13px;
  font-size: 12px;
  font-weight: 500;
  background-color: #E6E9EF;
  color: #01215D;
}

.rentRoll .ant-tabs-nav::before {
  border-bottom: 0px transparent;
}

.counter-field-disabled {
  background-color: #f7f7f8;
  color: #d0d4d7;
}

.counter-field {
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  padding: 3px;
  margin: auto;
}

.counter-field p {
  margin-bottom: 0;
}

.counter-field .dec-num-btn,
.counter-field .inc-num-btn {
  border: 0;
  background-color: #e5e5e56b;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter-field .dec-num-btn {
  border-radius: 4px 0px 0px 4px;
}

.counter-field .inc-num-btn {
  border-radius: 0px 4px 4px 0px;
}

.counter-field .dec-num-btn:hover,
.counter-field .inc-num-btn:hover,
.counter-field .dec-num-btn:focus,
.counter-field .inc-num-btn:focus {
  background-color: #00215b !important;
  color: #fff !important;
  border-radius: 5px;
}

#rc-tabs-1-panel-2>div>div:nth-child(5)>div:nth-child(2)>div>div>div>div.ant-table-wrapper.second-table>div>div>div>div>div>table>tbody {
  background-color: #f2f4f7;
}

#rc-tabs-1-panel-2>div>div:nth-child(5)>div:nth-child(2)>div>div>div>div.ant-table-wrapper.second-table>div>div>div>div>div>table>thead>tr {
  display: none;
}

#rc-tabs-1-panel-2>div>div:nth-child(5)>div:nth-child(2)>div>div>div>div.ant-table-wrapper.second-table.mt-3>div>div>div>div>div>table {
  border-spacing: 0px 8px;
}

.calculation-cascader {
  width: 270px;
}

.assumptionsParameter .input-headings {
  background-color: #E6E9EF;
  color: #344D7D;
  font-weight: bold;
  border-radius: 5px;
  padding: 7px;
  font-size: 16px;
  font-weight: 600;
}

.assumptionsParameter .ant-input:focus {
  background-color: #f2f4f7 !important;
}

.calculationComponent .ant-input {
  border: 1px solid #E5E5E5 !important;
  color: #1F1F1F;
  padding: 9.5px 12px !important;
  text-align: right;
}

.calculationComponent .addon-right>div>input {
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.calculationComponent .ant-input-number-input {
  border: 1px solid #E5E5E5 !important;
  color: #1F1F1F;
  padding: 9.5px 12px !important;
  text-align: right;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border-radius: 5px;
  transition: all 0.3s;
  height: unset;
}

.slider-inputs .ant-input-number-input {
  text-align: center !important;
}

.calculationComponent .ant-input-number-input[disabled] {
  color: #b3b5b8;
  background-color: #f7f7f8;
  cursor: not-allowed;
  opacity: 1;
}

.removeStyles {
  all: unset
}

.calculationComponent .ant-input-group-addon,
.calculationComponent .ant-input-number-group-addon {
  border: 1px solid #E5E5E5 !important;
}

.ant-input-number-group-addon {
  color: #797979;
  font-size: 17px;
  text-align: center;
  background-color: #f7f7f8;
}

.ant-input-group-addon,
.ant-input-number-group-addon {
  padding: 0;
  width: 2.2rem;
}

.ant-input-group>.ant-input-group-addon:not(:first-child),
.ant-input-group>.ant-input-number-group-addon:not(:first-child) {
  border-left: 0 !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-input-group>.ant-input:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  ;
}

.calculationComponent .ant-input.noBorder {
  border: none !important;
  /* text-align: center; */
}

.calculationComponent .noBorder>input {
  border: none !important;
}

.calculationComponent .noBorder>div>input {
  border: none !important;
}

.calculationComponent .ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #b7b7b7;
  border-radius: 5px;
  transition: all 0.3s;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.calculationComponent .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #E5E5E5 !important;
  display: flex;
  align-items: center;
}

.calculationComponent .ant-select-selection-placeholder {
  color: #a9a9a9 !important;
}

.calculationComponent .anticon {
  color: #595a5c !important;
}

.calculationComponent .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* height: 47px; */
  height: 42px;
}

.assumptionsParameter .top-left-input {
  border-top-left-radius: 5px !important;
}

.assumptionsParameter .bottom-right-input {
  border-bottom-right-radius: 5px !important;
}

.assumptionsParameter .bottom-left-input {
  border-bottom-left-radius: 5px !important;
}

.assumptionsParameter .top-right-input {
  border-top-right-radius: 5px !important;
}

.assumptionsParameter .term-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.assumptionsParameter .reletting-input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

#rc-tabs-1-panel-7>div>div:nth-child(3)>div:nth-child(2)>div>div>div>div.ant-table-wrapper.second-table.mt-3>div>div>div>div>div>table>thead {
  display: none;
}

#rc-tabs-1-panel-7>div>div:nth-child(3)>div:nth-child(2)>div>div>div>div.ant-table-wrapper.second-table.mt-3>div>div>div>div>div>table>tbody {
  background-color: #f2f4f7;
}

#rc-tabs-1-panel-7>div>div:nth-child(3)>div:nth-child(2)>div>div>div>div.ant-table-wrapper.second-table.mt-3>div>div>div>div>div>table {
  border-spacing: 0px 8px;
}

.ant-tabs-ink-bar::after {
  content: " ";
  position: absolute;
  left: 55%;
  right: 0;
  bottom: 0;
  height: 2px;
  background: #00215b;
  width: 80% !important;
  transform: translateX(-55%);
}

.assetDetails .ant-tabs-tab:active,
.assetDetails .ant-tabs-tab:focus {
  color: #00215b;
}

.statisticsArea .ant-tabs-nav-wrap .ant-tabs-nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.statisticsArea .ant-tabs-nav-wrap {
  /* border: 1.5px solid #B0BACD */
}

.assets-sidebar__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-top: 16px; */
}

.assets-sidebar__tabs button {
  /* background: #FFF1EA; */
  background: #E6E9EF;
  border: none;
}

.assets-sidebar__tabs button span svg {
  /* color: #FF7228; */
  color: #01215d;
  /* font-size: 19px;s */
}

.assets_card {
  border: 1px solid #D0D5DD;
}

.inflation-card .ant-card-body {
  padding: 10px 16px !important;
}

.inflation-erv-card .ant-card-body {
  padding: 10px 16px 20px 16px !important;
}

.financing-assumption-card .ant-card-body {
  padding-bottom: 5.2rem !important;
}

.acquisition-cost-card .ant-card-body {
  padding-bottom: 5.1rem !important;
}

.cashflow-assumption-card .ant-card-body {
  padding-bottom: 5rem !important;
}

.assets_card__header {
  border-bottom: 1px solid #B0BACD;
}

.assets_card__header h3 {
  font-size: 18px;
  font-weight: 500;
}

.inner_progress {
  color: #1F1F1F !important;
  font-size: 16px;
  font-weight: 500;
}

.inner_price {
  color: #797979;
  font-size: 16px;
  font-weight: 400;
}

.incDec__inner>div>div>input {
  text-align: center !important;
}

.incDec__inner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  position: relative;
}

.incDec__inner span {
  position: absolute;
  /* right: 21px; */
  /* right: 8px */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  text-align: right !important;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}

.text-table {
  font-size: 18px;
  font-weight: 500;
}

.calculation-table .ant-table-cell {
  background-color: #fff;
}

.erv-calculation-table table>tbody>tr:hover .ant-table-cell,
.erv-calculation-table table>tbody>tr .ant-table-cell {
  background-color: #f4f4f4;
  color: rgb(121, 121, 121);
}

.dcf-result__row {
  position: absolute;
  right: 43px;
  z-index: 1;
  top: 10px;
  gap: 9px;
  display: flex;
}

.dcf-result__row .ant-btn-circle {
  border-radius: 10px;
}

.holding_text {
  color: #797979;
  font-size: 16px;
  font-weight: 500;
}

.assumption_fields input {
  border: none !important;
}

.assumption_text {
  color: #5E5E5E;
  font-size: 16px;
  font-weight: 500;
}

.assumption_heading {
  font-weight: 500;
  font-size: 18px;
}

.drawer_text h6 {
  color: #01215D;
  font-size: 14px;
  font-weight: 500;
}

.drawer_text p {
  color: #9B9B9B;
  font-weight: 500;
}

.drawer_text,
.area-percentage__bar {
  border-bottom: 1px solid #E6E9EF;
  padding-bottom: 11px;
}

.area-percentage__bar p {
  color: #011E55;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.area-percentage__bar h6 {
  color: #1F1F1F;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.drawer-progress,
.drawer-rent-progress {
  border: 1px solid #B0BACD;
  padding: 0px 9px;
  border-radius: 10px;
  /* margin-bottom: 10px; */
}

.drawer-rent-progress .ant-progress .ant-progress-outer {
  padding: 0 !important;
}

.drawer-tabs button {
  font-size: 14px;
  font-weight: 500;
  color: #011742;
  border: 1px solid #011742;
  background-color: #fff;
  padding: 12px 0;
  width: 50%;
  cursor: pointer;
}

.drawer-tabs button:first-child {
  border-radius: 10px 0px 0px 10px;
}

.drawer-tabs button:nth-child(2) {
  border-radius: 0px 10px 10px 0px;
}

.drawer-tabs button.active {
  background-color: #01215D;
  color: #fff !important;
}

.assets-col {
  padding-top: 10px;
}

.text-orange {
  color: #FF7228;
}